<template>
  <div v-if="isBannerVisible && config.active"
       class="flex items-center justify-center w-full px-16 md:px-32 sm:h-[80px] bg-cover bg-center"
       :style="{backgroundImage: 'url(' + '/static/images/promo/bf-bg.png' + ')'}">
    <!-- Dummy item for flex -->
    <!--    <div/>-->
    <div class="py-12 !pl-0 w-full xs:w-auto">
      <div class="flex items-center justify-between md:justify-center flex-col gap-3 sm:flex-row w-full xs:w-auto">
        <div class="bg-purple-100 text-white text-b-s w-full xs:w-auto font-bold italic px-16 py-1 ribbon flex justify-center gap-20">
          <p class="whitespace-nowrap" v-html="config.bannerTitle"/>
          <p class="w-[105px] whitespace-nowrap">{{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}</p>
        </div>
        <div class="flex items-center w-full justify-between xs:justify-center">
          <p class="banner-text text-white text-b-s md:text-h-l mr-16" v-html="config.bannerText"></p>
          <Link href="/advisors/top">
            <se-button type="cta" class="!px-32 hidden md:inline-block md:visible">
              {{ config.cta }}
            </se-button>
            <se-button type="cta" size="small" class="!px-24 inline-block md:hidden">
              {{ config.cta }}
            </se-button>
          </Link>
        </div>
      </div>
    </div>
    <!--    <se-icon class="cursor-pointer" name="x" @click="closeBanner"/>-->
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {getStorageValue, setCookie} from '@/src/helpers/permanentValueStorage';
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {theme} from '@/theme';
import Link from '@/src/components/Link';
import SeButton from '@/src/shared/ui-kit/button/Button';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';

const {$route} = getGlobalProperties()
const config = theme.promo.blackFriday;
const userClosedBanner = ref(false);
userClosedBanner.value = getStorageValue('blackFridayBannerHidden') == "true";

const isBannerVisible = computed(() => !userClosedBanner.value && $route.path !== '/dashboard/chat' && !$route.path.includes('/get-started') && !$route.path.includes('/dashboard/inbox'));

const closeBanner = () => {
  userClosedBanner.value = true;
  setCookie('blackFridayBannerHidden', true);
};

const end = config.endDate;
const days = ref('')
const hours = ref('')
const minutes = ref('')
const seconds = ref('')

const timer = setInterval(showRemaining, 1000);

function showRemaining() {
  const now = new Date();
  const distance = end - now;
  if (distance < 0) {
    clearInterval(timer);
    return;
  }
  
  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;
  days.value = ('0' + Math.floor(distance / _day)).slice(-2);
  hours.value = ('0' + Math.floor((distance % _day) / _hour)).slice(-2);
  minutes.value = ('0' + Math.floor((distance % _hour) / _minute)).slice(-2);
  seconds.value = ('0' + Math.floor((distance % _minute) / _second)).slice(-2);
}
</script>

<script lang="ts">
export default {
  name: 'SeHeaderBlackFridayBanner',
};
</script>

<style lang="scss">
.ribbon {
  @apply w-full xs:w-fit;
  --r: .6em;
  line-height: 16px;
  clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
}

.banner-text br {
  @apply xs:hidden
}
</style>
