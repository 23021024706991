import {onMounted, onUnmounted, ref, computed} from 'vue';
import {theme} from "@/theme";

const useBlackFriday = (): any => {
  const bfConfig = theme.promo.blackFriday;
  const checkDate = () => bfConfig.startDate && new Date() >= bfConfig.startDate &&
    bfConfig.endDate && new Date() <= bfConfig.endDate;

  const blackFridayActivePeriod = ref(checkDate());
  const isBlackFridayActive = computed(() => bfConfig.active && blackFridayActivePeriod.value)
  let blackFridayCheckInterval: number;

  if (!import.meta.env.SSR) {
    const checkDealStarted = () => blackFridayActivePeriod.value = checkDate();

    onMounted(() => {
      checkDealStarted();
      blackFridayCheckInterval = setInterval(checkDealStarted, 1000)
    });

    onUnmounted(() => {
      clearInterval(blackFridayCheckInterval)
    })
  }
  return {isBlackFridayActive};
};

export {
  useBlackFriday,
};
