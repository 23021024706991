<template>
  <div ref="headerSearch" class="l-header__search">
    <template v-if="isMounted">
      <div class="w-32 h-32 md:w-44 md:h-44 flex items-center justify-center cursor-pointer" @click="open">
        <se-icon name="search" :size="20" />
      </div>
      <div ref="searchInput" class="l-header__search__popover flex items-center" :class="{ 'is-open': isOpen }">
        <se-input
          v-model="searchQuery"
          class="flex-grow"
          type="search"
          :size="isMobile ? 'small' : 'medium'"
          placeholder="Search"
          :clearable="true"
        >
          <template #prefix>
            <se-icon name="search" :size="20" />
          </template>
        </se-input>
        <div class="flex items-center pl-8 md:pl-32 !h-40 md:!h-56 bg-white !-mt-12 !pt-12">
          <se-button type="primary" size="small" @click="search">
            Search
          </se-button>
          <se-button
            v-if="!isMobile"
            native-type="button"
            type="tertiary"
            size="small"
            class="ml-8"
            @click="isOpen = false"
          >
            Cancel
          </se-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, computed, onMounted, onUnmounted, nextTick,
} from 'vue';
import SeInput from '@/src/shared/ui-kit/input/Input';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeButton from '@/src/shared/ui-kit/button/Button';
import { getWindow } from '@/src/shared/helpers/getWindow';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';

const searchQuery = ref<string>('');
const searchInput = ref<HTMLElement | null>(null);
const headerSearch = ref<any>(null);
const isMounted = ref(false);

const { $router } = getGlobalProperties();

const { windowWidth } = getWindow();
const isMobile = computed(() => windowWidth.value < 768);

const isOpen = ref<boolean>(false);

const open = () => {
  isOpen.value = true;
  nextTick(() => {
    const input = searchInput.value?.querySelector('input');
    if (input) {
      input.focus();
    }
  });
};

const search = () => {
  $router.push(`/search?q=${searchQuery.value}`);
  isOpen.value = false;
};

const handleClickOutside = (event: any) => {
  if (headerSearch.value && !headerSearch.value.contains(event.target)) {
    isOpen.value = false;
  }
};

onMounted(() => {
  isMounted.value = true;
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  isMounted.value = false;
});
</script>

<script lang="ts">
export default {
  name: 'SeHeaderSearch',
};
</script>
