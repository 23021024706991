<template>
  <div v-if="isBannerVisible"
       class="bg-gradient-primary flex items-center justify-between w-full px-16 md:px-32">
    <!-- Dummy item for flex -->
    <div/>
    <div class="px-16 py-12 !pl-0">
      <div class="flex items-center justify-between md:justify-center">
        <p class="text-white text-b-s md:text-h-l mr-16">
          Upload Profile Pictures with <b>2x Better</b> Quality!
        </p>
        <Link @click="closeBanner" href="/dashboard/profile/edit">
          <se-button type="tertiary" class="bg-white hover:bg-gray-100 !px-32 hidden md:inline-block md:visible">
            Update Now
          </se-button>
          <se-button type="tertiary" size="small" class="bg-white hover:bg-gray-100 !px-24 inline-block md:hidden">
            Update Now
          </se-button>
        </Link>
      </div>
    </div>
    <se-icon class="cursor-pointer" name="x" @click="closeBanner"/>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {getStorageValue, setCookie} from '@/src/helpers/permanentValueStorage';
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {theme} from '@/theme';
import Link from '@/src/components/Link';
import SeButton from '@/src/shared/ui-kit/button/Button';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';

const {$route} = getGlobalProperties()
const config = theme.general;
const userClosedBanner = ref(false);
userClosedBanner.value = getStorageValue('featureBannerHidden') == "true";

const isBannerVisible = computed(() => !userClosedBanner.value && $route.path !== '/dashboard/chat' && !$route.path.includes('/get-started') && !$route.path.includes('/dashboard/inbox'));

const closeBanner = () => {
  userClosedBanner.value = true;
  setCookie('featureBannerHidden', true);
};
</script>

<script lang="ts">
export default {
  name: 'SeHeaderFeatureBanner',
};
</script>
