import { onMounted, onUnmounted, ref } from 'vue';

const getWindow = (): any => {
  const windowWidth = ref(0);
  const windowHeight = ref(0);
  if (!import.meta.env.SSR) {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;

    const updateSize = () => {
      windowWidth.value = window.innerWidth;
      windowHeight.value = window.innerHeight;
    };
    onMounted(() => {
      updateSize();
      window.addEventListener('resize', updateSize);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateSize); // Clean up
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateSize);
    })
  }
  return { windowWidth, windowHeight };
};
export {
  getWindow,
};
